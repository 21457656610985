export const ja = {
  paymentfailed: {
    title: "アカウントはダウングレードされました",
    description: "お支払い情報の更新",
    body1:
      "お使いのカードへ請求が失敗したためクレジットカード情報を更新する必要があります。<br>現在のお客様はフリープランへダウングレードされています。",
    do: "支払情報の更新",
    "need-login": "ログインしてから再度このページを開いてください",
  },
  click: "クリック",
  team: {
    description:
      'チームを作成し請求とアカウント管理を1つにまとめることができます。<br>詳しくは<a href="/blog/about/2022/09/23/about-team?utm_source=hstorage">こちら</a>をご覧ください。',
    manual: "手動でメールアドレスを入力",
    manual_placeholder: "複数の場合は改行してください",
    csv: "または CSV でアップロード",
    csv_format: "CSV フォーマット",
    can_not_invite: "チームに所属しているため招待することはできません",
    invite: "チームへ招待",
    invite_date: "招待した日時",
    invite_count: "招待する人数",
    invite_done: "招待が完了しました🎉",
    confirm: "確認",
    confirm_text: "下記のユーザーへ招待を送ります💁",
    need_subscription: "チームの作成にはプレミアム・ビジネスプランが必要です",
    you_are_member: "あなたは下記のアカウントのチームメンバーです",
    member: "メンバー",
    status: {
      title: "ステータス",
      0: "保留中",
      1: "利用中",
    },
    member_count: "メンバー数",
    name: "名前",
    delete: {
      message:
        "メンバーを削除するとメンバーがアップロードしたファイル・グループは管理者（あなた）へ所有権が移動されますが、30日後に自動で削除されます。メンバーのアカウントはすぐに削除されます",
      error: {
        invalid_email: "メールアドレスの書式が誤っています",
        choose: "ファイルを選択してください",
      },
    },
  },
  free_user: "フリーユーザー",
  premium_user: "プレミアムユーザー",
  business_user: "ビジネスユーザー",
  developer_user: "デベロッパーユーザー",
  cancel: "キャンセル",
  email: "メールアドレス",
  email_file: {
    need_login: "メールを送信するためにログインする必要があります",
    modal: {
      title: "メール送信",
      description: "あなたにかわって我々が相手にメールを送信します",
      submit: "送信",
    },
  },
  about: {
    dashboard: {
      title: "ファイル管理",
      description:
        "ダッシュボードでいろいろな方法でファイルを管理することができます。",
      list: {
        title: "アップロードしたファイルのチェック",
        description:
          "ダッシュボードでは過去にアップロードしたファイルの確認などが行なえます。直リンク用のURLを取得したり、ファイルを共有するための「共有URL」の取得などもこの画面で行います。グループに追加したりファイルを削除したり、はたまた暗号化をした場合にはここでパスワードの確認をしましょう。",
      },
      group: {
        title: "グループ管理でタグ付け",
        description:
          "グループにアップロードしたファイルを追加することができます。グループ単位でファイルを検索したり、グループを公開することでグループに追加されたファイルを外部へ公開することもできます。グループを削除すればグループに追加されたファイルも一緒に削除することができます。",
      },
    },
    security: {
      title: "セキュリティに対する取り組み",
      description:
        "お客様の情報を守る弊社のセキュリティに対する取り組みをご紹介します。",
      "file-check": {
        title: "リアルタイムウイルスチェック",
        description:
          "HStorageはアップロードされるすべてのファイルに対してリアルタイムでウイルスチェックが行われます。万が一ウイルスが検知された場合にはダウンロード時に警告を出すような仕組みを設けています。",
      },
      information: {
        title: "暗号化された個人情報",
        description:
          "お客様のメールアドレスやクレジットカード情報は弊社のサーバーに保存されません。ユーザー管理を行うのは <a href='https://auth0.com' target='_blank' rel='noreferrer'>Auth0</a>、クレジットカード情報は <a href='https://stripe.com' target='_blank' rel='noreferrer'>Stripe</a> で管理しています。セキュリティに関することはセキュリティに重点を置くサービスを利用する弊社のポリシーによって実現しています。",
      },
      encrypt: {
        title: "ファイル自体を暗号化",
        description:
          "この暗号化システムはアップロード時に有効にすることで利用できます。他のクラウドストレージ・アップローダーとは違い、ファイルそのものを暗号化することで万が一ファイルが流出してしまった場合でも復元することができなくなります。パスワードを相手の方に伝えればファイルの共有も可能です。",
      },
      backend: {
        title: "透明性",
        description:
          "お客様の情報がどのように処理、管理されているかを HStorageでは構成図を公開しています。",
      },
    },
  },
  asct: {
    about: {
      title: "役務の提供時期",
      value: "登録後、ただちに利用が可能です",
    },
    address: {
      title: "所在地",
      value: "〒105-0013 東京都港区浜松町2-2-15",
    },
    description:
      "「特定商取引に関する法律」第11条（通信販売についての広告）に基づき以下に明示いたします",
    owner_company: {
      title: "販売業者",
      value: "HCloud Ltd",
    },
    owner_person: {
      title: "販売責任者",
      value: "長谷川 拓也",
    },
    support_email: {
      title: "サポートメールアドレス",
      value: "support@hstorage.io",
    },
    support_phone: {
      title: "サポート電話番号",
      value: "070-2667-2002",
    },
    support_time: {
      title: "サポート対応時間",
      value: "10:00-19:00",
    },
    payment_method: {
      title: "お支払い方法",
      value: "クレジットカード、銀行振込、コンビニ決済",
    },
    payment_period: {
      title: "お支払い時期",
      value:
        "<ul><li>クレジットカード決済：注文時に直ちに処理されます。</li><li>コンビニ決済：注文から3日以内にお支払いください。</li><li>銀行振込：注文から3日以内にお支払いください。</li></ul>",
    },
    offer: {
      title: "サービス提供時期",
      value: "登録後、ただちに利用が可能です",
    },
    fee: {
      title: "追加手数料",
      value: "商品/サービスの特性上 送料は発生いたしません",
    },
    cancel: {
      title: "キャンセルについて",
      value:
        "ログイン後ダッシュボードの設定ページの下部よりキャンセルが可能です",
    },
    price: {
      title: "販売価格",
      value: "プランに準ずる",
    },
    title: "特定商取引法",
  },
  close: "閉じる",
  contact: {
    title: "お問い合わせ",
  },
  continue: "続ける",
  copy: "コピー",
  dashboard: {
    api: {
      description: "APIドキュメント",
      title: "APIキー",
    },
    api_only: "APIユーザー限定",
    create: "作成",
    group: {
      admin: {
        changed: "公開状態を変更しました",
        copy: "コピー",
        description:
          "ファイルをグループに追加してタグ付けのようにグループで管理できます。グループを公開することもできます。<br>詳しくは<a href='https://hstorage.io/blog/usage/2022/08/13/about-group' target='_blank' rel='noreferrer'><b>こちら</b></a>",
        label: {
          count: "ファイル数",
          name: "名前",
          view_status: "グループの公開",
          upload_status: "アップロードを許可",
          view_url: "グループ公開URL",
          delete: "削除",
        },
        preview: "プレビュー",
        title: "グループ",
      },
      upload_info:
        "<span class='has-text-primary'>{n}</span> へ<br class=\"is-hidden-desktop\">アップロード・共有されます",
      already: "すでに存在しています",
      created: "グループを作成しました",
      delete: {
        message: "グループにあるファイルはすべて削除されますがよろしいですか？",
      },
      none: "未設定",
    },
    onlyPremium: "プレミアム限定",
    password: {
      description: "暗号化についてはこちらをご覧ください",
      title: "暗号化",
    },
    placeholder: {
      1: "ファイル名、グループ名",
    },
    premium_only: "プレミアムユーザー限定",
    usage: {
      title: "使用状況",
      count: "ファイル数",
      total_size: "アップロード容量",
    },
    search: "検索",
    setting: {
      title: "設定",
      payment: "支払い方法の変更・領収書の発行",
    },
    tag: {
      none: "なし",
    },
    title: "ダッシュボード",
  },
  delete: {
    delete: "削除",
    already_deleted: "ファイルはすでに削除されています",
    deleted: "削除されました",
    error: {
      not_allowed:
        "このファイルを削除する権限はあなたにないか、既に削除されています",
    },
  },
  delete_account: {
    execute: "退会する",
    sub_title: "全てのデータが削除されます",
    title: "ユーザー退会",
  },
  dropzone: {
    upload: "アップロード",
    setting: {
      password: {
        title: "パスワードを設定しますか？",
        description:
          "パスワードを設定してファイルを保護することができます。直リンクでの利用はできません。",
      },
    },
    limit: {
      continue: "アップロード",
    },
    error: {
      max_files_20_description:
        "無料の同時アップロード数は「20」です<br>プレミアムプランになると合計5GB以内で無制限に同時アップロードできます",
      max_files_20_title: "プランのアップグレードが必要です",
    },
    init: "サーバーへ接続しています",
  },
  error: {
    error: "エラーが発生しました。解決までお待ちください。",
    title: "エラー",
    connection: {
      title: "サーバーに接続できませんでした",
      message:
        "一時的にメンテナンスの可能性があります。サービスの稼働状況は<a href='https://status.hcloud.ltd/status/hstorage' rel='noreferrer'>こちら</a>で確認できます。",
    },
    please_set_email:
      "メールアドレスが取得できませんでした。<br>SNS にメールアドレスを登録し、再ログインをお試しください💁",
  },
  faq: {
    description:
      "よくある質問で解決できない場合は<a href='/contact'>こちら</a>よりお問い合わせください",
    1: {
      title: "保存期間について",
      value:
        "フリープランではサーバーの容量を確保するため、ファイルが古いものから順に削除される場合がございます。しかしサービス開始以降、これを実施したことはございません。プレミアム・ビジネスプランでは容量が無制限です。",
    },
    2: {
      title: "ファイルが閲覧・検閲されることはありますか？",
      value:
        "弊社がお客様のファイルを閲覧することはありません。また、ファイルサーバーへのアクセス権限は弊社代表社員のみが所有しております。法令により弊社が強制捜査を受けた場合には、お客様のファイルを開示することがあります。",
    },
    4: {
      title: "問題のあるファイルを見つけました",
      value:
        "問題のあるファイルを見つけた際には、お手数ですが<a href='/contact'>こちら</a>よりご連絡ください。",
    },
    5: {
      title: "発信者情報開示の方法を教えてください",
      value:
        'IPアドレスなどの開示は、個人でも可能です。フローは<a href="https://hstorage.io/blog/usage/2022/08/07/information-disclosure.html" target="_blank" rel="noreferrer">こちら</a>です。',
    },
    6: {
      title: "構成図",
    },
    8: {
      title: "セキュリティについて教えてください",
      value:
        "ユーザー情報は自社では保全せずにセキュリティレベルの高い Auth0 で管理しています。<br>クレジットカード情報も自社で管理せず、Stripe を利用しています。またファイルに対する辞書攻撃はレートリミットにより防御しています。<br>第三者によるセキュリティ監査を定期的に実施しております。<a href='https://hstorage.io/blog/news/2024/09/07/security-audit' target='_blank' rel='noreferrer'>詳しくはこちらをご覧ください。</a>",
    },
    9: {
      title: "メールアドレスの変更方法を教えてください",
      value: "お手数ですが<a href='/contact'>こちら</a>よりご連絡ください",
    },
    11: {
      title: "アカウントを削除をしたい",
      value: "ログイン後、メニューにある「ユーザー退会」から可能です。",
    },
    12: {
      title: "フリートライアルに関して",
      value:
        "月額プランでは7日間無料でご利用になれます。期間中の解約でも料金は発生しません。2回目のフリートライアルは適用されません。",
    },
    13: {
      title: "問い合わせ先を教えてください",
      value: "<a href='/contact'>こちら</a>よりお問い合わせください",
    },
    14: {
      title: "共有リンクと直リンクの違いはなんですか？",
      value:
        "共有リンクはファイルを共有するために利用します。直リンクはサイトに埋め込んだり、アプリケーションに組み込むときに利用します。",
    },
    15: {
      title: "日時指定の削除について",
      value:
        "削除する日時を設定していただくことにより、自動でファイルを削除することができます。設定した日時を最大30分以上超えて削除される場合があります。",
    },
    18: {
      title: "アカウントが制限されています",
      value:
        "フリーアカウントを複数所持している可能性があります。心当たりがない場合は同じ場所（オフィスなど）で他の方が既にアカウントを所持している可能性があります。使用していないアカウントを削除（退会）していただくことで最大30分以内に制限が解除されます。<br>プレミアム・ビジネスユーザーはこの制限を受けません。",
    },
    category: ["ファイル", "セキュリティ", "アカウント", "その他"],
    title: "よくある質問",
  },
  footer: {
    description: "SNSアカウントで簡単に無料で利用することができます",
  },
  list: {
    buttons: {
      direct: "直リンクURLコピー",
      download: "ダウンロード",
      share: "共有URLコピー",
    },
    info: {
      count: "表示回数：",
      date: "日時：",
      size: "容量：",
    },
    label: {
      group: "グループ",
    },
    video: {
      done: {
        title: "変換済み",
        value:
          "視聴がしやすいように動画ファイルは変換されました。元データは引き続きダウンロードができます。",
      },
      in_progress: {
        title: "変換中",
        value:
          "視聴がしやすいように動画ファイルを変換中です。元データは引き続きダウンロードできます。",
      },
      in_queue: {
        title: "変換中",
        value:
          "視聴がしやすいように動画ファイルを変換中です。元データは引き続きダウンロードできます。",
      },
    },
  },
  login: "ログイン",
  logout: "ログアウト",
  navbar: {
    reload: "更新",
    top: "トップ",
  },
  only_premium_long:
    "この機能はプレミアム限定です。7日間のフリートライアルができます 💁",
  privacy: {
    1: "個人情報保護に関する法令遵守",
    "1_1":
      "利用者の個人情報の取扱、利用その他一切の個人情報の取扱いについて、個人情報の保護に関する法律、通信の秘密の係る電気通信事業法の規定、その他の関連法令、電気通信事業における個人情報保護に関するガイドラインを遵守します。",
    2: "Cookieの利用について",
    "2_1":
      "HStorage ではお客様によりよいサービスを提供するため サイト上の一部コンテンツにおいてCookie(クッキー)を利用しております。その際、取得される情報はお客様個人を識別できるような情報を含みません。また、下記に掲げる第三者企業へCookieで取得した情報を参考情報として提供しております。提供した情報は主にサイト上の広告表示の最適化に使用されます。その他、Cookieの利用、オプトアウト(Cookie（クッキー）の利用に対する拒否権)について 詳細な情報をご希望のお客様はお問い合わせフォームよりご連絡くださいませ。",
    3: "利用規約・免責事項",
    "3_1":
      "著作権法違反にあたるファイルのアップロード・ダウンロードの一切を禁止します。 その他公序良俗に反するファイルのアップロード・ダウンロードの一切を禁止します。 これらに該当するファイルを発見した場合は予告なく運営側はその判断をもってファイルの削除をできるものとします。",
    4: "免責事項",
    "4_1":
      "ファイルの転送の不良およびサーバー障害によるファイルの損失が生じた場合でも、 当サイトおよび運営会社はいかなる責任も負わないものとします。また、広告等外部サイトでの一切の利用行為にも 当サイトおよび運営会社はいかなる責任も負わないものとします。その他、いかなる損害に対しても当サイトおよび運営会社は責任は負わないものとします。",
    5: "利用規約への同意",
    6: "運営会社",
    "5_1":
      "利用に当たっては、その使用をもって、この利用規約および免責事項に同意したものとみなします。当サイトおよび運営会社は事前の通告なしに全ての条項を改訂できるものとします。",
    title: "プライバシーポリシー",
  },
  show: {
    security: {
      check: {
        passed: "このファイルは安全です",
        infected: "ウイルスが発見されました",
      },
    },
    access_count: "アクセス数: ",
    button: {
      direct: "直リンクURL",
      download: "ダウンロード",
      download_encrypt: "ダウンロードするには暗号キーを入力",
      share: "共有URLをコピー",
    },
    deleted: "このファイルは削除されました",
    description: {
      1: "他人にこのファイルを共有したい場合、",
      2: "このページのURLを共有してください",
    },
    download_password: "ダウンロードするにはパスワードを入力してください",
    encrypted: "このファイルは暗号化されています",
    group: {
      error: {
        not_found: "このグループは存在しないか、すでに削除されています。",
        not_public_view:
          "このグループは公開されていません。<br>オーナーに公開状態を変更してもらうようにご連絡ください。",
        not_public_upload:
          "このグループへのアップロードは許可されていません。<br>オーナーに権限の変更をしてもらうようにご連絡ください。",
      },
      title: "グループページ",
    },
    info: {
      count: "表示回数：",
      date: "日時：",
      size: "容量：",
    },
    password: {
      need: "パスワードを入力してください",
    },
    play: "再生",
    title: "ファイル詳細",
  },
  sidebar: {
    action: "アクション",
    menu: "メニュー",
  },
  signup: "アカウント作成 —無料",
  signup_short: "アカウント作成",
  subscription: {
    annotation: {
      1: "※ フリートライアルは2回目以降、適用されません。",
      2: "※ プレミアム、ビジネスにおいて保管容量 500GB ごとに 1,500円の従量課金があります。",
    },
    already: "契約中",
    api: {
      1: "API の提供",
      2: "その他制限はプランに準ずる",
      get: "API を取得",
      price: "980",
      title: "開発者向け",
    },
    free: {
      1: "{n}MB以下のファイルは永久保存",
      4: "無制限の直リンク",
      5: "月間{n}ファイルまでのアップロード",
      6: "1つまでのグループ管理",
      7: "アカウントで合計{n}GBまでの保存",
      8: "ファイルの暗号化機能",
      price: "0円",
      title: "無料",
    },
    login: "ログイン / 登録",
    per_month: "/ 月",
    per_year: "/ 年",
    off: "20% OFF",
    business: {
      1: "共有ページにおいて広告の非表示",
      2: "最も優先的なサポート",
      3: "FTP によるアップロード",
      4: "アカウント一括管理機能",
      5: "LDAP / Active Directory によるユーザー連携",
    },
    premium: {
      1: "Free の機能",
      2: "無制限の同時アップロード数",
      3: "無制限の月間アップロード数",
      4: "無制限の保存容量",
      5: "無制限のグループ管理機能",
      6: "広告の非表示",
      7: "画像のリアルタイム処理",
      8: "リスト機能を拡張",
    },
    title: "プラン",
    trial: "7日間のフリートライアル",
  },
  success: {
    title: "プランのアップグレードに成功しました",
    api: "API キーはダッシュボード画面から確認できます。",
  },
  tokutyou: {
    title: "特徴",
  },
  top: {
    features: {
      title: "機能",
      description: "HStorage が提供するクラウドストレージの機能一覧",
    },
    update: {
      title: "最新の情報",
      description: "Hstorage の公式ブログから最新の情報です",
    },
    check_email: "メールにアカウント認証URLを送信しました",
    delete_date: "削除する日時を決めますか？",
    delete_placeholder: "ここをクリックして削除する日時を決めましょう",
    deleted: "そのファイルは削除されました 💁",
    encrypt: {
      title: "暗号化する",
    },
    error: {
      max_upload_size: "100GB 以上のファイルはアップロードできません",
      already_deletecomparisond: "このファイルは既に削除されています",
      browser:
        "エラーを検知しました。問題がある場合はブラウザのプライバシー（セキュリティ）設定を見直してください。",
      create_subscription:
        "決済が失敗しました。問い合わせいただけると幸いです、、、",
      customer_already:
        "既にプレミアムユーザーです。決済は行われませんのでご安心を",
      db_ping:
        "現在一時的にサービスが停止しています。このエラーは通知済みです。回復状況は Twitter や トップページ、または https://status.hcloud.ltd/status/hstorage で確認できます",
      delete: "ファイルを削除するのに失敗しました...😫 解決までお待ち下さい",
      do_not_match_email:
        "ログイン情報のメールアドレスと、決済時のメールアドレスが不一致です。再度ご確認ください",
      error:
        "不明なエラーがおきました...🙏 サーバーがメンテナンスの可能性もあります。このエラーは管理者に通知済みです",
      ext: "このファイルはフリープランではアップロードできません",
      "failed_to_request_pre-signed":
        "署名付きURLの取得に失敗しました。このエラーは通知済みです。回復状況は Twitter や トップページ、または https://status.hcloud.ltd/status/hstorage で確認できます",
      file_name_not_found:
        "不思議なエラーが発生しました。このエラーは通知済みです。回復状況は Twitter や トップページ、または https://status.hcloud.ltd/status/hstorage で確認できます",
      get_upload_info: "そのファイルは既に削除されています",
      getUserByEmailJWT: "一度ログアウトしてからログインし、再度お試しください",
      getUserFromAuth0: "一度ログアウトしてからログインし、再度お試しください",
      invalid_ext: "ファイルの拡張子が不明です。ファイル名を確認してください",
      isUserPremium:
        "ユーザーがプレミアムかどうか識別するのに失敗しました。このエラーは通知済みです。回復状況は Twitter や トップページ、または https://status.hcloud.ltd/status/hstorage で確認できます",
      limit_exceeded_files:
        "フリープランでは月間20ファイルまでアップロード可能です。プレミアム・ビジネスユーザーになることをご検討いただけますと幸いです。",
      limit_exceeded_capacity:
        "フリープランではアカウント全体で容量 10GB、1,000ファイルまで保存できます。使っていないファイルを削除していただくかプレミアム・ビジネスユーザーになることをご検討いただけますと幸いです。",
      duplicated_user:
        "このアカウントは複数アカウントが確認されたため制限されています。詳しくはよくある質問をご覧ください",
      login_undefined:
        "ログインすることができませんでした。ブラウザの拡張機能でセキュリティ機能があるものや、OS のウイルス対策ソフトの原因が考えられます",
      no_login: "ログインして再度お試しください",
      no_registered_token: "10分以上経過したため、再度手順を踏んでください",
      no_subscription:
        "お支払いが見つかりません。もし引き落としされているようであれば問い合わせください",
      no_support:
        "そのファイルは対応していません...プレミアムになると制限がなくなります！😃 詳しくは「よくある質問」をご覧ください",
      not_allowed: "このファイルを削除する権限がありません",
      not_exists: "このファイルは存在しません",
      parseJWT:
        "ユーザー情報の取得に失敗しました、、、。解決までお待ちください",
      referer:
        "セキュリティソフトによって必要な情報が送信できていません。一時的に無効にしてください",
      restriction:
        "このファイルはアップロードできません。ZIP 等に圧縮してください",
      shorted:
        "短縮URLの取得に失敗しました。このエラーは通知済みです。お急ぎの場合は短縮URLを無効化してみてください。回復状況は Twitter やトップページ、または https://status.hcloud.ltd/status/hstorage で確認できます",
      unknown:
        "不明なエラーがおきました...🙏 サーバーがメンテナンスの可能性もあります。このエラーは管理者に通知済みです",
      update_role:
        "ユーザー情報の更新に失敗しました。決済はされいないのでご安心ください。問い合わせください！！！",
      url_not_found: "正しい URL を入力してください",
    },
    faq: "よくある質問",
    file_name: {
      period: "ファイル名にピリオドを含めることはできません",
      placeholder: "拡張子は不要です（.jpg .png 等）",
      title: "ファイル名を変更する",
      value:
        "アップロードするときに名前を変更することができます。複数同時アップロードすると「ファイル名-01」のようになります。",
    },
    send_email: "メールを送信しました",
    server_download: "サーバーから送信",
    server_info_1:
      "100% に近いほどアップロード速度に影響する可能性があります。",
    server_info_2: "HStorage は専用線の 1Gbps 回線を利用しています。",
    server_load: "サーバー混雑度：",
    server_upload: "サーバーへアップロード",
    subtitle: "大きなファイルは時間がかかるのでそのままお待ち下さい",
    top: "トップ",
    tokutyou: {
      title: "サービスの特徴",
      description:
        "HStorageは世界から月20万人に訪問され、月間3,500万回のファイルを配信しています。<br>今までセキュリティ事故は無く、信頼性を提供し続けています。",
    },
    verification_email: "再送信",
    verification_relogin:
      "メールアドレス認証が完了しました。再ログインしてください。",
    hero: "<span style='background: linear-gradient(to right, #5AB0E3, #4672B8); color: transparent; -webkit-background-clip: text; background-clip: text;'>セキュアで</span><span style='color: #ED3573; text-shadow: 0px 2px 2px #FFE9F1;'>高速な</span><br><span>クラウドストレージ</span>",
    catch:
      "高機能なクラウドストレージサービスとして HStorage は無料でセキュアなファイル管理と高速なネットワークを使って個人だけでなくビジネスにも信頼性を提供します。",
    count: {
      1: "HStorage は",
      2: "数のファイルを提供しています",
    },
    card: {
      tile: {
        security: {
          title: "セキュリティ",
          encrypt: {
            title: "暗号化",
            desc: "ファイルを暗号化して弊社サーバーに保存することで流出のリスクを抑える。<br>他サービスにはない高度なセキュリティ。",
          },
          virus: {
            title: "リアルタイムウイルスチェック",
            desc: "アップロードされたファイルは全てリアルタイムでウイルスチェックが行われます。<br>問題があると該当ファイルのダウンロード時に警告が表示されます。",
          },
        },
      },
      download: {
        download: "ダウンロード",
        count: "回数制限",
        limit_date: "有効期限",
        password: "パスワード",
        encrypt: "暗号化",
      },
      infinite: {
        storage: "容量 ∞",
        count: "ファイル ∞",
      },
      processing: "画像変換",
      upload:
        "<span style='font-size:1.4em'>100</span> GB<br>アップロード<span style='font-size:0.7em'>/回</span>",
      group: "グループ管理",
      security: {
        title: "強力な<br class='is-mobile'>セキュリティ",
        description:
          "HStorageはすべてのファイルにウイルスチェックを行い、万が一ウイルスが検知された場合にはダウンロード時に警告を出すような仕組みを設けています。また、暗号化機能ではファイルそのものを暗号化することで流出した場合でも復元はできません。",
        link: "弊社のセキュリティについて知る",
      },
      free: {
        title: "無料で使える",
        description:
          "会員登録（無料）をするだけで利用可能です。クレジットカードの情報も不要です。",
      },
      direct: {
        title: "直リンク可能",
        description:
          "アップロードしたファイルは劣化せずに直リンクで開くことが可能です。",
      },
      network: {
        title: "ネットワーク",
        description:
          "5GBファイルも1分以内にアップロード・ダウンロードすることができます。",
      },
      dashboard: {
        title: "多彩な<br class='is-mobile'>ファイル管理",
        description:
          "アップロードしたファイルはグループ管理や、コレクション機能によってさまざまな管理方法を提供しています。ファイルにダウンロード回数や、有効期限を設定して自動でファイルを削除することも可能です。とくにグループ管理とコレクション機能はオススメです。",
        link: "ダッシュボード機能について知る",
      },
    },
  },
  tos: {
    description:
      "HStorage（以下、当サイト)は、本利用規約に同意される方のみ利用できる。<br>尚、本利用規約は当サイトへアクセスし、サービスを利用した時点で有効とする。同意しない場合は利用できない。",
    3: "利用規約を要約したもの",
    4: "1. 日本国憲法及び法律を守る。",
    5: "2. 自己責任で利用し管理者は一切の責任を負わない。",
    6: "3. 常識の範囲内で利用する。",
    7: "サービスの利用に関して",
    8: "1. 当サイトのすべては自己責任で利用するものとし、生じた不利益について管理者は一切の責任を負わない。",
    9: "2. 当サイトを利用する際に生じる全ての権利を管理者が持つものとする。（アップロードされたファイルに関する権利を除きます。）",
    10: "3. 利用規約は利用者に告知することなく変更できる。変更内容は改定と同時に適用される。",
    11: "4. 日本の法律が適用される。",
    12: "5. 当サイトのすべては予告無しに提供を中止、停止する可能性がある。",
    13: "6. 何らかのトラブルなどにより、利用規約を理解する事ができない場合はサービスを利用しない。",
    14: "アップロードされたファイルに関して",
    15: "1. アップロードされたファイルに関するすべてはアップロードした利用者が責任を持ち、管理者は一切の責任を負わない。",
    16: "2. アップロードされたファイルに関する第三者との問題はアップロードした利用者が責任を持って解決する。",
    17: "3. アップロードされたファイルに関して何らかの問題がある場合、管理者は処置する事ができるが、その義務はない。",
    18: "禁止行為",
    19: "1. 著作権など、他者の権利を侵害する行為。",
    20: "2. 日本の法律に違反する行為。",
    21: "3. 故意にサーバーに負担をかける行為。",
    22: "4. 運営に支障を来す恐れのある行為。",
    23: "5. 当社は法令などにより情報の開示を求められる場合以外、アップロードされたファイルを閲覧、検閲する行為。",
    24: "情報の開示/処置",
    25: "1. 当サイトの利用規約に違反、開示しなければならないと管理者が判断した場合は利用者の接続情報などを開示し、処置する事ができる。これは利用者が拒否することはできない。",
    26: "2. 法令などにより情報の開示を求められた場合、管理者は利用者の接続情報などの情報を第三者に開示する事ができる。これは利用者が拒否することはできない。",
    27: "管理者について/権限",
    28: "1. 当サイトのすべてを管理者は、利用者・第三者・要望に関係無く処置、管理（削除など）することができる。また、その義務はない。",
    29: "2. 当サイトのすべてを管理者は、法令などにより情報の開示を求められる場合以外、アップロードされたファイルを閲覧、検閲してはならない。",
    30: "2024 / 09 / 08 更新",
    title: "利用規約",
  },
  unsubscription: {
    do: "退会する",
    title: "サブスクリプションの解除",
    plan: "プランの解約",
    premium: "プレミアムプランの解除",
    api: "APIプランの解除",
    business: "ビジネスプランの解除",
  },
  update: {
    title: "更新履歴",
  },
  uploaded: {
    title: "アップロード完了",
    buttons: {
      direct: "直リンクURLをコピー",
      processing: "画像処理",
      share: "共有URLをコピー",
    },
    download_limit: "ダウンロード回数の指定",
    download_limit_description:
      "ダウンロードできる回数を指定します。回数に達するとファイルは自動的に削除されます。",
    group: {
      add: {
        description:
          "グループに追加することでファイルをグループ管理できるようになります",
        do: "追加",
        done: "グループに追加しました 💁",
        title: "グループに追加",
      },
      create: {
        description:
          "グループをここで作成することもできます。作成後グループに追加してください。",
        do: "作成",
        title: "グループ作成",
      },
      name: "グループ名",
    },
    hankaku: "半角数字",
    only_premium: "プレミアム限定",
    setting: "設定",
  },
  maintenance: {
    title: "現在メンテナンス中です👷",
    1: "現在メンテナンス中です。数十分でメンテナンスが終了します。",
    2: "Twitter でメンテナンス状況が告知されています。",
  },
  it_security_basic_policy: {
    title: "情報セキュリティ基本方針",
    content:
      "合同会社HCloud（以下、当社）は、お客様からお預かりした/当社の/情報資産を事故・災害・犯罪などの脅威から守り、お客様ならびに社会の信頼に応えるべく、以下の方針に基づき全社で情報セキュリティに取り組みます。 <br><br><br>" +
      "<h2 class='title'>経営者の責任</h2>" +
      "<p>当社は、経営者主導で組織的かつ継続的に情報セキュリティの改善・向上に努めます。</p>" +
      "<br><br>" +
      "<h2 class='title'>社内体制の整備</h2>" +
      "<p>当社は、情報セキュリティの維持及び改善のために組織を設置し、情報セキュリティ対策を社内の正式な規則として定めます。</p>" +
      "<br><br>" +
      "<h2 class='title'>従業員の取組み</h2>" +
      "情報セキュリティのために必要とされる知識、技術を習得し、情報セキュリティへの取り組みを確かなものにします。" +
      "<br><br><br>" +
      "<h2 class='title'>法令及び契約上の要求事項の遵守</h2>" +
      "<p>当社は、情報セキュリティに関わる法令、規制、規範、契約上の義務を遵守するとともに、お客様の期待に応えます。</p>" +
      "<br><br>" +
      "<h2 class='title'>違反及び事故への対応</h2>" +
      "<p>当社は、情報セキュリティに関わる法令違反、契約違反及び事故が発生した場合には適切に対処し、再発防止に努めます。</p>",
    footer: "2021年8月27日 制定<br>" + "合同会社 HCloud",
  },
};
